<template>
  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/kontakt" :title="title" class="breadcrumb">
            {{ title }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">

        <div class="col s12 l2">
          <MenuSite/>
        </div>
        <div class="col s12 l10 " :class="{loading: stateCompare('loading')}">

          <div class="site-text">

            <h1 class="big-title mb-3">{{ title }}</h1>
            <div v-html="text"></div>

          </div>
        </div>

      </div>
    </div>
    <br><br><br>


  </main>
</template>

<script>
import MenuSite from "@/components/MenuSite";
import axios from "@/axios2";
import ComponentListStateMachine from "../Extends/ComponentListStateMachine";

export default {
  name: 'Site',
  extends: ComponentListStateMachine,
  components: {MenuSite},
  props: {
    slug: String
  },
  data() {
    return {
      title: null,
      text: null,
    }
  },
  methods: {
    refresh: function () {


      this.stateUpdate('sendInfo');
      axios
          .get('site/find', {
            params: {
              url: this.$route.params.slug,
              scope: 'title,text'
            }
          })
          .then(response => {

            let data = response.data.data;

            this.title = data.title
            this.text = data.text

            this.$root.metaSet( this.title, this.text )

            this.stateUpdate('sendSuccess');

          })

          .catch(error => {

            this.stateUpdate('sendWrong');
            this.refresh();

          });

    }
  },
  created() {
    this.refresh();
  },
  watch: {

    '$route.params.slug': {
      handler: function (slug) {

        this.refresh();

      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
